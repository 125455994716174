export enum RecaptchaActionKey {
  Invalidate = 'invalidate',
  SetToken = 'set-token',
  Reset = 'reset',
}

export interface IRecaptchaAction {
  action: RecaptchaActionKey;
  value?: string;
}
