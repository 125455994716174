import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingComponent } from './components/loading/loading.component';

import { LoginComponent } from './components/login/login.component';
import { MarketingContentComponent } from './components/marketing-content/marketing-content.component';
import { PasswordComponent } from './components/password/password.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { SsoLoginErrorComponent } from './components/sso-login-error/sso-login-error.component';
import { AutofocusDirective } from './directives/auto-focus/auto-focus';

import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { ShowPasswordDirective } from './directives/show-password/show-password';

import { LoginPageComponent } from './pages/login/login-page.component';
import { RequestPasswordPageComponent } from './pages/request-password/request-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password/reset-password-page.component';
import { SelectorPageComponent } from './pages/selector/selector-page.component';

import { AppConfigResolver } from './resolvers/app-config-resolver';
import { LocationResolver } from './resolvers/location-resolver';
import { AppConfigService } from './services/app-config/app-config.service';
import { CookieService } from './services/cookie/cookie.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { LoginService } from './services/login/login.service';
import { RoutesConfigService } from './services/routes-config/routes-config.service';
import { SelectorService } from './services/selector/selector.service';
import { environment } from '../environments/environment';

import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ResetPasswordPageComponent,
    RequestPasswordPageComponent,
    SelectorPageComponent,
    LoginComponent,
    SsoLoginErrorComponent,
    PasswordComponent,
    RecaptchaComponent,
    ClickOutsideDirective,
    AutofocusDirective,
    ShowPasswordDirective,
    MarketingContentComponent,
    LoadingComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    SelectorService,
    AppConfigService,
    RoutesConfigService,
    CookieService,
    LocalStorageService,
    LoginService,
    AppConfigResolver,
    LocationResolver,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: environment.jenkins ? '' : '/login' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
