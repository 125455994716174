import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
} from '@angular/core';
import { Router } from '@angular/router';
import { ILoginState } from '../../models/login';

enum SSOError {
  AccountOIDCNotEnabledError = 'AccountOIDCNotEnabledError',
  OIDCSubConflictError = 'OIDCSubConflictError',
  OIDCLoginOPEmailConflictError = 'OIDCLoginOPEmailConflictError',
  OIDCOPEmailUnverifiedError = 'OIDCOPEmailUnverifiedError',
  OIDCBadGatewayError = 'OIDCBadGatewayError',
  OIDCGatewayTimeoutError = 'OIDCGatewayTimeoutError',
}

@Component({
  selector: 'edtd-sso-login-error',
  templateUrl: './sso-login-error.component.html',
  styleUrls: ['./sso-login-error.component.scss'],
})
export class SsoLoginErrorComponent implements OnChanges {
  get loginState(): ILoginState {
    return this.currentLoginState;
  }

  @Input()
  set loginState(state: ILoginState) {
    this.currentLoginState = state;
  }

  @Output()
  updateState: EventEmitter<ILoginState> = new EventEmitter<ILoginState>();

  public ssoError: string;

  protected currentLoginState: ILoginState;

  constructor(private router: Router) {}

  public ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (changes.loginState) {
      const { ssoErrorCode, ssoErrorName, ssoErrorDescription } =
        changes.loginState.currentValue;

      if (!ssoErrorCode && !ssoErrorName && !ssoErrorDescription) {
        return;
      }

      switch (changes.loginState.currentValue.ssoErrorCode) {
        case '400':
          this.ssoError = changes.loginState.currentValue.ssoErrorDescription;
          break;

        case '401':
          this.ssoError = 'Unexpected error occurred. Please try again.';
          break;

        case '409':
          this.ssoError = this.getErrorByName(ssoErrorName);
          break;

        case '404':
        case '500':
          this.ssoError =
            'Internal Error. We are unable to process your request at this time, please try again.';
          break;

        default:
          this.ssoError =
            'An error occurred whilst authorising your sign on. Please try again. If this persists please contact <a href="mailto:support@edited.com">EDITED support</a> or your internal IT support for further assistance.';
          break;
      }
    }
  }

  public onSubmit() {
    this.router.navigateByUrl('/');

    this.updateState.emit(
      Object.assign({}, this.loginState, {
        component: 'login',
        ssoErrorCode: null,
      })
    );
  }

  private getErrorByName(errorName: SSOError) {
    switch (errorName) {
      case SSOError.AccountOIDCNotEnabledError:
        return 'Your login is valid, but your company\'s EDITED implementation is not configured for single sign on. Please contact <a href="mailto:support@edited.com">EDITED support</a> for assistance with this issue.';
      case SSOError.OIDCSubConflictError:
        return 'The unique identifier <em>(OIDC Subject)</em> the single sign on provider sent us does not match the one we have on record for your account. Verify you have signed into EDITED with the correct username. Also verify that you used the correct account when signing into your single sign on provider’s login page for EDITED. Please contact your internal IT support for further assistance with this issue.';
      case SSOError.OIDCLoginOPEmailConflictError:
        return 'The email address returned to us from your single sign on provider does not match the email address used to log into EDITED. Verify you are signing into EDITED with the correct username and that you are using the correct account when signing into your single sign on provider’s page. Please contact your internal IT support for further assistance with this issue.';
      case SSOError.OIDCOPEmailUnverifiedError:
        return 'Your single sign on provider has informed us that you have not yet verified your email address with them yet. You must verify your email address with them before logging into EDITED. Please contact your internal IT support for further assistance with this issue.';
      case SSOError.OIDCBadGatewayError:
        return 'An error occurred contacting your sso provider. Please try again. If this persists please contact <a href="mailto:support@edited.com">EDITED support</a> or your internal IT support for further assistance.';
      case SSOError.OIDCGatewayTimeoutError:
        return 'Your single sign on provider is not responding to requests in a timely manner. Please try again. If this persists please contact your internal IT support for further assistance with this issue.';
      default:
        return 'An unrecognised error has occurred between EDITED and your SSO provider. Please contact <a href="mailto:support@edited.com">EDITED support</a> for assistance with this issue.';
    }
  }
}
