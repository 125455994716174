<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="login-form"
  name="loginForm"
  novalidate
>
  <img
    class="logo"
    src="/login/assets/images/my-edited-logo.svg"
    alt="myEDITED"
  />

  <div
    *ngIf="isFieldInvalid('email') || loginState.strategyError"
    class="error-container error"
  >
    <img src="/login/assets/images/error.svg" />
    <div class="error-message">
      <p *ngIf="isFieldInvalid('email')">Invalid Email Format</p>
      <p *ngIf="loginState.strategyError">
        Internal Error. We are unable to process your request at this time,
        please try again.
      </p>
    </div>
  </div>

  <div *ngIf="loginState.isSessionTimeOut" class="alert alert-info">
    <i class="icon fa fa-info-circle"></i><strong>Session time out</strong>
    <div>Your session has expired, please login to continue.</div>
  </div>

  <div *ngIf="loginState.isResetSuccess" class="alert alert-success">
    <strong>Password Saved.</strong><br />Please login to continue.
  </div>

  <div [class.error]="isFieldInvalid('email')" class="form-group">
    <div class="input-icon left">
      <div class="form-input">
        <label>Email</label>
        <input
          edtdAutofocus
          formControlName="email"
          name="email"
          type="email"
        />
        <input
          name="password"
          style="display: none"
          tabindex="-1"
          type="password"
        />
      </div>
    </div>
  </div>

  <div class="form-actions">
    <div class="row">
      <div class="col-md-12 rememberEmail">
        <input formControlName="remember" id="rememberEmail" type="checkbox" />
        <label for="rememberEmail">Remember Email Address</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn primary full-width" type="submit">Continue</button>
      </div>
    </div>
    <div class="row">
      <label class="col-md-12 privacy-policy">
        By clicking continue, you agree to our
        <a
          href="https://edited.net/privacy-policy/"
          rel="noopener noreferrer"
          target="_blank"
          >Privacy Policy</a
        >.
      </label>
    </div>
  </div>
</form>
