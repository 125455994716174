import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { AppConfigService } from '../../services/app-config/app-config.service';
import { LoginService } from '../../services/login/login.service';

import {
  IRecaptchaAction,
  RecaptchaActionKey,
} from '../../models/recaptcha-action';

declare interface IWindow {
  grecaptcha: any;

  onRecaptchaLoad(): void;
}

declare const window: IWindow;

@Component({
  selector: 'edtd-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
})
export class RecaptchaComponent implements OnDestroy {
  @ViewChild('recaptcha', { static: true })
  recaptchaElement: ElementRef;

  public showError = false;

  private script: HTMLScriptElement;
  private widgetId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private loginSvc: LoginService,
    private appConfig: AppConfigService,
    private zone: NgZone,
    private render: Renderer2
  ) {
    window.onRecaptchaLoad = () => {
      this.init();

      this.subscriptions.push(
        this.loginSvc.recaptchaControls.subscribe(
          (recaptcha: IRecaptchaAction) => {
            switch (recaptcha.action) {
              case RecaptchaActionKey.Invalidate:
                this.showError = true;
                break;
              case RecaptchaActionKey.Reset:
                this.reset();
                this.setToken(null);
                break;
            }
          }
        )
      );
    };

    this.addScript();
  }

  public ngOnDestroy() {
    this.render.removeChild(this.script.parentNode, this.script);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public reset(): void {
    window.grecaptcha.reset(this.widgetId);
  }

  private init(): void {
    const config = {
      sitekey: this.siteKey,
      callback: this.onSuccess.bind(this),
      'expired-callback': this.onExpired.bind(this),
    };

    this.widgetId = window.grecaptcha.render(
      this.recaptchaElement.nativeElement,
      config
    );
  }

  private setToken(value: string | null): void {
    this.zone.run(() => {
      this.showError = false;
    });

    this.loginSvc.recaptchaControls.next({
      action: RecaptchaActionKey.SetToken,
      value,
    });
  }

  private onSuccess(token: string): void {
    this.setToken(token);
  }

  private onExpired(): void {
    this.setToken(null);
  }

  private addScript(): void {
    const src =
      '//www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';

    this.script = document.createElement('script');

    this.script.setAttribute('type', 'text/javascript');
    this.script.setAttribute('src', src);

    document.body.appendChild(this.script);
  }

  private get siteKey(): string {
    return this.appConfig.getConfig('recaptcha').publicKey;
  }
}
