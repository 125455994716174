import { Injectable } from '@angular/core';

declare interface IDocument {
  cookie: string;
}

declare const document: IDocument;

export interface ICookieOptions {
  expires?: string | Date;
}

export interface ICookieService {
  getCookie(key: string): string;

  setCookie(key: string, value: string, options?: ICookieOptions): void;
}

@Injectable()
export class CookieService implements ICookieService {
  protected get cookieString(): string {
    return document.cookie || '';
  }

  protected set cookieString(val: string) {
    document.cookie = val;
  }

  public getCookie(key: string): string {
    return this.cookieReader()[key];
  }

  public setCookie(key: string, value: string, options?: ICookieOptions): void {
    this.cookieWriter(key, value, options);
  }

  private cookieReader(): { [key: string]: string } {
    const lastCookies = {};
    const currentCookieString = this.cookieString;

    if (currentCookieString === '') {
      return {};
    }

    const cookieArray = currentCookieString.split('; ');

    for (const cookie of cookieArray) {
      const index = cookie.indexOf('=');

      if (index === 0) {
        continue;
      }

      const cookieName = cookie.substring(0, index);

      if (
        typeof lastCookies[cookieName] === 'undefined' ||
        lastCookies[cookieName] === null
      ) {
        lastCookies[cookieName] = cookie.substring(index + 1);
      }
    }

    return lastCookies;
  }

  private cookieWriter(
    name: string,
    value: string,
    options?: ICookieOptions
  ): void {
    this.cookieString = this.buildCookieString(name, value, options);
  }

  private buildCookieString(
    name: string,
    value: string,
    options?: ICookieOptions
  ): string {
    const cookieValue = value;
    let cookieString = name + '=' + cookieValue;
    let expires: any = options && options.expires;

    if (value === '') {
      expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
    }

    if (typeof expires === 'string') {
      expires = new Date(expires);
    }

    cookieString += expires ? `;expires=${expires.toUTCString()}` : '';

    return cookieString;
  }
}
