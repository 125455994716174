import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CookieService } from '../../services/cookie/cookie.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { LoginService } from '../../services/login/login.service';

import {
  ILoginState,
  ILoginSuccessResponse,
  IStrategySuccessResponse,
} from '../../models/login';
import { RecaptchaActionKey } from '../../models/recaptcha-action';

declare const window: Window;

@Component({
  selector: 'edtd-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public isInit: boolean;

  protected currentLoginState: ILoginState;

  private subscriptions: Subscription[] = [];
  private cookieExpire: Date;

  get loginState(): ILoginState {
    return this.currentLoginState || {};
  }

  set loginState(state: ILoginState) {
    this.currentLoginState = state;
  }

  protected get location(): Location {
    return window.location;
  }

  constructor(
    private cookieSvc: CookieService,
    private loginSvc: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageSvc: LocalStorageService
  ) {
    const hasGID = this.loginSvc.hasGID(
      this.activatedRoute.snapshot.data.location.hash,
      false
    );

    if (hasGID) {
      window.location.href = encodeURIComponent(
        this.activatedRoute.snapshot.data.location.hash
      );
    }
  }

  public ngOnInit() {
    const isRememberEmailAddress = this.localStorageSvc.get(
      'isRememberEmailAddress'
    );
    const rememberUsername = this.localStorageSvc.get('rememberUsername');
    const hasLoginErr = Boolean(this.loginSvc.hasLoginErrCode(this.router.url));
    const hasLogout = this.loginSvc.hasLogout(this.router.url);
    const hasRequestNewPass = this.loginSvc.hasRequestNewPass(this.router.url);
    const hasResetPass = this.loginSvc.hasResetPass(this.router.url);
    const hasSetUsername = this.loginSvc.hasSetUsername(this.router.url);
    const isSessionTimeOut = Boolean(
      this.localStorageSvc.get('sessionTimeOut')
    );

    this.cookieExpire = new Date();
    this.cookieExpire.setFullYear(this.cookieExpire.getFullYear() + 1);

    this.localStorageSvc.remove('sessionTimeOut');

    if (isRememberEmailAddress === 'true' && rememberUsername) {
      this.cookieSvc.setCookie('saved_username', rememberUsername, {
        expires: this.cookieExpire,
      });

      this.localStorageSvc.remove('isRememberEmailAddress');
      this.localStorageSvc.remove('rememberUsername');
    }

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params) =>
        this.onLocationChange(params)
      )
    );

    if (
      !hasLoginErr &&
      !hasRequestNewPass &&
      !hasResetPass &&
      !hasSetUsername
    ) {
      const baseObject = {
        isSessionTimeOut,
      };
      const savedUsername = this.cookieSvc.getCookie('saved_username');

      if (savedUsername && !hasLogout) {
        this.onStateChange(
          Object.assign({}, baseObject, {
            action: 'doStrategy',
            username: savedUsername,
          })
        );
      } else {
        this.loginState = Object.assign({}, baseObject, { component: 'login' });
      }
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public onStateChange(state: ILoginState): void {
    this.loginState = state;

    const { username, password, recaptchaResponse } = state;

    switch (state.action) {
      case 'doLogin':
        this.isLoading = true;
        this.loginSvc
          .login({ username, password, recaptchaResponse })
          .subscribe(
            (res) => this.loginSuccess(res),
            (res) => this.loginError(res)
          );
        break;

      case 'doStrategy': {
        const snapshot = this.activatedRoute.snapshot;
        const landingLocation = snapshot.data.location;
        const queryParams: any = {};
        const hasLoginErr = Boolean(
          this.loginSvc.hasLoginErrCode(landingLocation.hash)
        );
        const hasLogout = this.loginSvc.hasLogout(landingLocation.hash);
        const hasRequestNewPass = this.loginSvc.hasRequestNewPass(
          landingLocation.hash
        );
        const hasResetPass = this.loginSvc.hasResetPass(landingLocation.hash);

        if (!hasLoginErr && !hasLogout && !hasRequestNewPass && !hasResetPass) {
          let appUrl = landingLocation.absUrl;

          if (snapshot.queryParams.appUrl) {
            appUrl = snapshot.queryParams.appUrl;
          }

          queryParams.appUrl = encodeURIComponent(appUrl);
        }

        this.isLoading = true;
        this.loginSvc.strategy(state.username, queryParams).subscribe(
          (res) => this.strategySuccess(res, state.username),
          () => this.strategyError()
        );
        break;
      }
    }
  }

  private onLocationChange(params: Params) {
    if (params.resetpassword === 'success') {
      this.loginState = Object.assign({}, this.loginState, {
        component: 'login',
        isResetSuccess: params.resetpassword === 'success',
      });
    }

    if (params.requestnewpass === 'success') {
      this.loginState = Object.assign({}, this.loginState, {
        component: 'password',
        isAlertShown: params.requestnewpass === 'success',
      });
    }

    if (params.setusername === 'success') {
      this.loginState = Object.assign({}, this.loginState, {
        component: 'password',
      });
    }

    if (params.email) {
      this.loginState = Object.assign({}, this.loginState, {
        username: decodeURIComponent(params.email),
      });
    }

    if (params.loginErrCode) {
      this.loginState = Object.assign({}, this.loginState, {
        component: 'sso-error',
        ssoErrorCode: params.loginErrCode,
        ssoErrorName: params.loginErrName,
        ssoErrorDescription: params.error_description,
      });
    }
  }

  private strategySuccess(
    response: IStrategySuccessResponse,
    email: string
  ): void {
    if (this.loginState.isRemember) {
      this.cookieSvc.setCookie('saved_username', this.loginState.username, {
        expires: this.cookieExpire,
      });
    } else {
      this.cookieSvc.setCookie('saved_username', '');
    }

    if (response.host && !window.location.href.includes('localhost')) {
      const appUrl = this.activatedRoute.snapshot.queryParams.appUrl;
      let url = `https://${response.host}/login?email=${encodeURIComponent(
        email
      )}`;

      if (appUrl) {
        url += `&appUrl=${encodeURIComponent(appUrl)}`;
      }

      return window.location.assign(url);
    }

    switch (response.strategy) {
      case 'oidc':
        window.location.href = response.redirect;
        break;

      case 'password':
        this.isLoading = false;
        this.loginState = Object.assign({}, this.loginState, {
          component: 'password',
          action: null,
        });
        break;
    }
  }

  private strategyError(): void {
    this.isLoading = false;

    this.loginState = Object.assign({}, this.loginState, {
      component: 'login',
      strategyError: true,
      action: null,
    });
  }

  private showRecaptcha(show: boolean): void {
    if (this.loginState.isCaptchaRequired) {
      this.loginSvc.recaptchaControls.next({
        action: RecaptchaActionKey.Reset,
      });
    } else {
      this.loginState = Object.assign({}, this.loginState, {
        isCaptchaRequired: show,
      });
    }
  }

  private loginSuccess(response: ILoginSuccessResponse): void {
    if (response.authenticated !== true) {
      return;
    }

    const curUrl = this.router.url;
    const hasGID = this.loginSvc.hasGID(curUrl);
    const hasRegularLink = this.loginSvc.hasRegularLink(curUrl);
    const hasRedirectLink = this.loginSvc.hasRedirectLink(curUrl);

    if (this.loginState.isRemember) {
      this.cookieSvc.setCookie('saved_username', this.loginState.username, {
        expires: this.cookieExpire,
      });
    } else {
      this.cookieSvc.setCookie('saved_username', '');
    }

    if (hasGID) {
      const landingLocation = this.activatedRoute.snapshot.data.location;
      const hash = landingLocation.hash.substring(1);

      try {
        window.location.hash = decodeURIComponent(hash);
      } catch (error) {
        window.location.hash = '';
      }

      window.location.reload();
    } else if (hasRegularLink) {
      this.loginSvc.initRedirection(curUrl);
    } else if (hasRedirectLink) {
      const appUrl = this.activatedRoute.snapshot.queryParams.appUrl;

      if (appUrl.includes('account=')) {
        window.location.href = decodeURIComponent(appUrl);
      } else {
        this.router.navigateByUrl(`/selector?appUrl=${appUrl}`);
      }
    } else {
      this.router.navigateByUrl('/selector');
    }
  }

  private loginError(response): void {
    const data = response.error;
    const status = response.status;
    const baseObject = {
      action: null,
      isLockedAccount: false,
      isInvalidUsernameAndPassword: false,
      isInvalidPassword: false,
      isWeakPassword: false,
      isPasswordError: false,
      isErrorShown: false,
      isNoAccessLogin: false,
    };

    this.isLoading = false;

    if (status === 401) {
      if (data.locked === true) {
        this.loginState = Object.assign({}, this.loginState, baseObject, {
          isLockedAccount: true,
        });
      } else if (data.noAccess) {
        this.loginState = Object.assign({}, this.loginState, baseObject, {
          isNoAccessLogin: true,
          isErrorShown: true,
        });
      } else {
        const newState: ILoginState = {
          isInvalidUsernameAndPassword: true,
        };

        if (data.authenticated !== null) {
          newState.password = '';
        }

        this.loginState = Object.assign(
          {},
          this.loginState,
          baseObject,
          newState
        );
      }
      // Show reCaptcha
      this.showRecaptcha(data.captchaRequired);
    } else {
      switch (data.code) {
        case 'WeakPasswordError':
          this.loginState = Object.assign({}, this.loginState, baseObject, {
            isWeakPassword: true,
            isErrorShown: true,
          });
          break;
        case 'InvalidPasswordError':
          this.loginState = Object.assign({}, this.loginState, baseObject, {
            isInvalidPassword: true,
            isErrorShown: true,
          });
          break;

        default:
          this.loginState = Object.assign({}, this.loginState, baseObject, {
            isPasswordError: true,
            isErrorShown: true,
          });
          break;
      }
    }
  }
}
