<div class="content">
  <div class="content-header">
    <div class="header">Try the myEDITED AI Summary</div>

    <div class="sub-header">
      Better, Quicker Retail Decisions With Advanced AI
    </div>

    <p>
      This powerful tool leverages Generative AI to instantly summarize key
      takeaways from your market data in real time. Now, with the click of a
      button, you can generate market analysis and spend more time actually
      taking action.
    </p>
  </div>
  <div class="buttons">
    <a class="default" href="https://my.edited.com">Take me to myEDITED</a>
  </div>
  <img src="/login/assets/images/insights.png" />
</div>
