<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="login-form"
  name="loginForm"
  novalidate
>
  <h1 class="form-title">Enter password</h1>

  <div *ngIf="isFieldInvalid('username')" class="error-container error">
    <img src="/login/assets/images/error.svg" />
    <div class="error-message">
      <p>Invalid Email Format</p>
    </div>
  </div>

  <div
    *ngIf="
      isFieldInvalid('password') ||
      loginState.isLockedAccount ||
      loginState.isInvalidUsernameAndPassword ||
      loginState.isErrorShown
    "
    class="error-container error"
  >
    <img src="/login/assets/images/error.svg" />
    <div class="error-message">
      <p *ngIf="isFieldInvalid('password')">Invalid Password</p>
      <p *ngIf="loginState.isLockedAccount">
        You have been locked out of this account. You have reached the limit for
        the number of consecutive failed login attempts. Please reset your
        password to unlock your account.
      </p>
      <p *ngIf="loginState.isInvalidUsernameAndPassword">
        Invalid Username or Password
      </p>
      <p *ngIf="loginState.isPasswordError">
        Internal Error. We are unable to process your request at this time,
        please try again.
      </p>
      <p *ngIf="loginState.isWeakPassword">
        Your password is weak and found in lists of common passwords that
        attackers can use to try to break into your account. Please reset your
        password.
      </p>
      <p *ngIf="loginState.isInvalidPassword">
        Your password no longer meets the complexity requirements of this site.
        Please reset your password.
      </p>
      <p *ngIf="loginState.isNoAccessLogin">
        Unexpected configuration error: your user account was not assigned to an
        Access Group and therefore lacks requisite permissions to load
        DynamicAction. Please contact your DynamicAction administrator or email
        support&#64;edited.com
      </p>
    </div>
  </div>

  <div *ngIf="loginState.isSessionTimeOut" class="alert alert-info">
    <i class="icon fa fa-info-circle"></i><strong>Session time out</strong>
    <div>Your session has expired, please login to continue.</div>
  </div>

  <div *ngIf="loginState.isAlertShown" class="alert alert-success">
    <strong>Thank you for requesting a new password.</strong>Your username will
    be validated and an email sent to {{ loginState.username }}. If you do not
    receive an email, please re-check the spelling of your username, check your
    spam folder, or contact your support representative to ensure that you are a
    registered user.
  </div>

  <p class="current-username">
    {{ loginState.username }}
  </p>
  <div class="different-user">
    <a
      (click)="goToLogin()"
      [routerLink]="['/']"
      [queryParams]="searchParamsWithoutEmail$ | async"
      type="button"
    >
      <span>Not you?</span>&nbsp;
      <span>Log in with a different email</span>
    </a>
  </div>

  <div
    [class.error]="isFieldInvalid('password') || isFieldInvalid('username')"
    class="form-group"
  >
    <div class="input-icon left">
      <div class="form-input">
        <input
          formControlName="username"
          name="username"
          style="display: none"
          tabindex="-1"
          type="text"
        />

        <label>Password</label>
        <input
          edtdAutofocus
          edtdShowPassword
          formControlName="password"
          name="password"
          type="password"
        />
      </div>
    </div>
  </div>

  <div *ngIf="loginState.isCaptchaRequired" class="form-group">
    <edtd-recaptcha></edtd-recaptcha>
  </div>

  <div class="form-actions">
    <div class="row">
      <div class="col-md-12">
        <a href="/login/requestpassword" id="forget-password"
          >Forgot password?</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn primary full-width" type="submit">Log in</button>
      </div>
    </div>
  </div>
</form>
