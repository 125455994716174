import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { LoginService } from '../../services/login/login.service';
import { RoutesConfigService } from '../../services/routes-config/routes-config.service';

import {
  IRecaptchaAction,
  RecaptchaActionKey,
} from '../../models/recaptcha-action';
import { EmailValidator } from '../../validators/email';

@Component({
  selector: 'edtd-request-password-page',
  templateUrl: './request-password-page.component.html',
  styleUrls: ['./request-password-page.component.scss'],
})
export class RequestPasswordPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public isRequestError: boolean;
  public isResetError: boolean;

  private subscriptions: Subscription[] = [];
  private recaptchaToken: string;
  private selectedEmail: string;

  constructor(
    private loginSvc: LoginService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private routesCfg: RoutesConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.form = this.formBuilder.group({
      email: [
        this.loginSvc.email,
        {
          validators: Validators.compose([
            Validators.required,
            EmailValidator.isValid(),
          ]),
          updateOn: 'submit',
        },
      ],
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.isResetError = params.status === 'expiredtoken';
    });

    this.subscriptions.push(
      this.loginSvc.recaptchaControls.subscribe(
        (recaptcha: IRecaptchaAction) => {
          if (recaptcha.action === RecaptchaActionKey.SetToken) {
            this.recaptchaToken = recaptcha.value;
          }
        }
      )
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public isFieldInvalid(fieldName: string): boolean {
    return this.loginSvc.isFieldInvalid(this.form, fieldName);
  }

  public onSubmit(): void {
    if (this.form.valid) {
      if (!this.recaptchaToken) {
        this.loginSvc.recaptchaControls.next({
          action: RecaptchaActionKey.Invalidate,
        });

        return;
      }

      this.selectedEmail = this.form.get('email').value;

      const resetParams = {
        username: this.selectedEmail,
        recaptchaResponse: this.recaptchaToken,
      };

      this.http.post(this.routesCfg.requestPassword(), resetParams).subscribe(
        () => this.requestSuccess(),
        (res) => this.requestError(res)
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    this.loginSvc.validateAllFormFields(formGroup);
  }

  private requestSuccess(): void {
    this.router.navigateByUrl(
      `/?requestnewpass=success&email=${encodeURIComponent(this.selectedEmail)}`
    );
  }

  private requestError(response): void {
    const status = response.status;

    if (status === 400) {
      this.loginSvc.recaptchaControls.next({
        action: RecaptchaActionKey.Invalidate,
      });
    } else {
      this.isRequestError = true;
    }

    // Refresh captcha every time when the button is pressed
    this.loginSvc.recaptchaControls.next({
      action: RecaptchaActionKey.Reset,
    });
  }
}
