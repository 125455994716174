<form (ngSubmit)="onSubmit()" class="sso-form">
  <h1 class="form-title">Log in to get started</h1>

  <div class="error-container error">
    <img src="/login/assets/images/error.svg" />
    <div class="error-message">
      <span [innerHTML]="ssoError"></span>
    </div>
  </div>

  <div class="form-actions">
    <div class="row">
      <div class="col-md-12">
        <button class="btn primary full-width" type="submit">Back</button>
      </div>
    </div>
  </div>
</form>
