import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AppConfigService } from '../../services/app-config/app-config.service';
import { LoginService } from '../../services/login/login.service';
import { RoutesConfigService } from '../../services/routes-config/routes-config.service';

import { EmailValidator } from '../../validators/email';
import {
  ConfirmPasswordValidator,
  PasswordValidator,
} from '../../validators/password';

@Component({
  selector: 'edtd-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
  public form: FormGroup;
  public passwordStrenght: any;
  public errorResetingPassword: string;

  private resetId: string;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private appCfg: AppConfigService,
    private activatedRoute: ActivatedRoute,
    private routesCfg: RoutesConfigService,
    private router: Router,
    private loginSvc: LoginService
  ) {}

  public ngOnInit() {
    this.passwordStrenght = this.appCfg.getConfig('password');

    this.form = this.formBuilder.group(
      {
        email: [
          '',
          {
            validators: Validators.compose([
              Validators.required,
              EmailValidator.isValid(),
            ]),
            updateOn: 'submit',
          },
        ],
        password: [
          '',
          {
            validators: Validators.compose([
              Validators.required,
              PasswordValidator.isValid(
                this.passwordStrenght.minChars,
                this.passwordStrenght.minCharGroups
              ),
            ]),
            updateOn: 'submit',
          },
        ],
        confirmPassword: [
          '',
          {
            validators: Validators.compose([
              Validators.required,
              PasswordValidator.isValid(
                this.passwordStrenght.minChars,
                this.passwordStrenght.minCharGroups
              ),
            ]),
            updateOn: 'submit',
          },
        ],
      },
      { validator: ConfirmPasswordValidator.isValid() }
    );

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.resetId = params['id'];
    });
  }

  public isValidResetId(): boolean {
    const uuidRegExp =
      /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

    return uuidRegExp.test(this.resetId);
  }

  public isFieldInvalid(fieldName: string): boolean {
    return this.loginSvc.isFieldInvalid(this.form, fieldName);
  }

  public onSubmit(): void {
    if (this.form.valid && this.isValidResetId()) {
      const resetParams = {
        username: this.form.get('email')?.value,
        password: this.form.get('password')?.value,
        resetId: this.resetId,
      };

      this.http.post(this.routesCfg.resetPassword(), resetParams).subscribe(
        () => this.resetSuccess(),
        (res) => this.resetError(res)
      );
    } else {
      if (!this.isValidResetId()) {
        this.errorResetingPassword =
          'Internal Error. We are unable to process your request at this time, please try again.';
      } else {
        this.errorResetingPassword = `Password should be at least${this.passwordStrenght.minChars} characters long and contain a minimum of ${this.passwordStrenght.minCharGroups} char groups.`;
      }

      this.validateAllFormFields(this.form);
    }
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    this.loginSvc.validateAllFormFields(formGroup);
  }

  private resetSuccess(): void {
    // Temporary change until global navigation from myEdited is implemented
    // this.router.navigateByUrl('/?resetpassword=success');
    window.location.href = 'https://edited.com/#login';
  }

  private resetError(response: Record<string, { code: string }>): void {
    const data = response['error'];
    const pastPasswordRecordMax =
      typeof this.passwordStrenght.pastPasswordRecordMax !== 'undefined'
        ? this.passwordStrenght.pastPasswordRecordMax
        : 3;

    this.form.controls['password'].setValue('');
    this.form.controls['confirmPassword'].setValue('');

    switch (data.code) {
      case 'ExpiredTokenError':
        this.router.navigateByUrl('/requestpassword?status=expiredtoken');
        break;

      case 'AccessDeniedError':
        this.errorResetingPassword =
          'The verification token has expired. Please request a new password reset.';
        break;

      case 'WeakPasswordError':
        this.errorResetingPassword =
          'Your password has been flagged as weak because it is very frequently used. Use a different password.';
        break;

      case 'PasswordReuseError':
        this.errorResetingPassword = `This password has been used before. You cannot reuse any of your previous ${pastPasswordRecordMax} passwords.`;
        break;

      default:
        this.errorResetingPassword =
          'Internal Error. We are unable to process your request at this time, please try again.';
        break;
    }
  }
}
