import { Injectable } from '@angular/core';

declare interface IWindow {
  localStorage: Storage;
}

declare const window: IWindow;

export interface ILocalStorageServiceService {
  add(key: string, value: string): boolean;

  get(key: string): string | null;

  remove(key: string): boolean;

  clearAll(): void;

  clearStartWith(startsWith: string): void;

  clearContains(containsStr: string): void;
}

@Injectable()
export class LocalStorageService implements ILocalStorageServiceService {
  protected get localStorage(): Storage {
    return window.localStorage;
  }

  public add(key, value): boolean {
    // 0 and "" is allowed as a value but let's limit other falsey values like "undefined"
    if (!value && value !== 0 && value !== '') {
      return false;
    }

    try {
      this.localStorage.setItem(key, value);
    } catch (e) {
      return false;
    }
    return true;
  }

  public get(key: string): string | null {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    return item;
  }

  public remove(key: string): boolean {
    try {
      this.localStorage.removeItem(key);
    } catch (e) {
      return false;
    }
    return true;
  }

  public clearAll(): void {
    for (const key in localStorage) {
      if (key in localStorage) {
        this.remove(key);
      }
    }
  }

  public clearStartWith(startsWith: string): void {
    if (typeof startsWith !== 'string') {
      return;
    }

    for (const key in localStorage) {
      if (key.startsWith(startsWith)) {
        this.remove(key);
      }
    }
  }

  public clearContains(containsStr: string): void {
    if (typeof containsStr !== 'string') {
      return;
    }

    for (const key in localStorage) {
      if (key.includes(containsStr)) {
        this.remove(key);
      }
    }
  }
}
