import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { RoutesConfigService } from '../routes-config/routes-config.service';

import { ChannelKey, IAccount, IChannel } from '../../models/account-channel';

interface ICachedAccountChannelSelection {
  account: string;
  channel: string;
}

@Injectable()
export class SelectorService {
  private readonly localStorageKey = 'loginAccountChannel';
  private accessObservable: Observable<any>;
  private lockAccessRequest = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageSvc: LocalStorageService,
    private routesConfig: RoutesConfigService,
    private activatedRoute: ActivatedRoute
  ) {}

  private get isNoAuth(): boolean {
    return this.router.url.includes('noAuth=true');
  }

  public isCachedSelectionAllowed(
    accounts: Array<IAccount>,
    { account, channel }: ICachedAccountChannelSelection
  ): boolean {
    const matchingAccount = accounts.find((x) => x.accountId === account);

    if (!matchingAccount) {
      return false;
    }

    const matchingChannel = matchingAccount.channels.find(
      (x) => x.id === channel
    );

    if (!matchingChannel) {
      return false;
    }

    return true;
  }

  public setRememberSelection(account?: string, channel?: string): void {
    if (!account || !channel) {
      this.localStorageSvc.remove(this.localStorageKey);
    } else {
      this.localStorageSvc.add(
        this.localStorageKey,
        JSON.stringify({ account, channel })
      );
    }
  }

  public getRememberSelection(): ICachedAccountChannelSelection | null {
    if (this.isNoAuth) {
      this.setRememberSelection();
    }

    const selection: string = this.localStorageSvc.get(this.localStorageKey);

    if (selection) {
      return JSON.parse(selection);
    }

    return null;
  }

  public getAllAccounts(): Observable<any> {
    return this.http.get(this.routesConfig.accounts());
  }

  public getAccessConfig(): Observable<any> {
    if (!this.lockAccessRequest) {
      this.lockAccessRequest = true;
      this.accessObservable = this.http
        .get(this.routesConfig.userAccess())
        .pipe(finalize(() => (this.lockAccessRequest = false)));
    }

    return this.accessObservable;
  }

  public getAccountsChannels(accessConfig: any): {
    lastAccount: string;
    data: any;
  } {
    const result = [];
    let filteredChannels = [];

    accessConfig.rows.forEach((item) => {
      if (
        !Array.isArray(item.permissions) ||
        !Array.isArray(item.enabledChannels)
      ) {
        return;
      }

      item.enabledChannels.forEach((channel) => {
        if (item.permissions.includes('channels.' + channel.toLowerCase())) {
          filteredChannels.push(channel);
        }
      });

      const hasPermissionsNoChannels = filteredChannels.length === 0;

      result.push({
        accountId: item.accountId,
        channels: this.processChannels(
          filteredChannels,
          item.accountId,
          hasPermissionsNoChannels
        ),
      });
      filteredChannels = [];
    });

    return { lastAccount: accessConfig.lastAccountId, data: result };
  }

  /**
   * Sort account channels based on predefined structure
   */
  public sortChannels(channels: IChannel[]): IChannel[] {
    const sortChannelsPattern: { [key: string]: number } = {
      Web: 1,
      Store: 2,
      Omni: 3,
    };

    return channels.sort((x, y) => {
      if (sortChannelsPattern[x.id] < sortChannelsPattern[y.id]) {
        return -1;
      } else if (sortChannelsPattern[x.id] > sortChannelsPattern[y.id]) {
        return 1;
      }

      return 0;
    });
  }

  public initRedirect(account: string, channel: string): void {
    const appUrl = this.activatedRoute.snapshot.queryParams.appUrl || '/';
    const appUrlParam = appUrl ? `&appUrl=${appUrl}` : '';
    const url = `${location.origin}?account=${account}&channel=${channel}${appUrlParam}`;

    location.assign(url);
  }

  public logoutRedirect(): void {
    this.router.navigateByUrl('/?logout=1');
  }

  private processChannels(
    channels: string[],
    accountId,
    noChannelsHasPermissions: boolean | null
  ): IChannel[] {
    let processedChannels: IChannel[] = [];

    if (!noChannelsHasPermissions && accountId !== 'ecommera') {
      processedChannels = channels.reduce(
        (processed: IChannel[], channel: ChannelKey) => {
          processed.push({
            id: channel,
            label: channel,
          });

          return processed;
        },
        []
      );
    } else {
      processedChannels = [
        {
          id: ChannelKey.Web,
          label: null,
          noChannels: true,
        },
      ];
    }

    return processedChannels;
  }
}
