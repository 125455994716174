import {
  Directive,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[edtdClickOutside]',
})
export class ClickOutsideDirective {
  @Output() private clickOutside = new EventEmitter();
  private nativeEl: HTMLElement;

  constructor(private el: ElementRef) {
    this.nativeEl = this.el.nativeElement;
  }

  @HostListener('document:click', ['$event.target']) public clicked(
    target
  ): void {
    if (!this.nativeEl.contains(target)) {
      this.clickOutside.emit(true);
    }
  }
}
