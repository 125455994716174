<main>
  <aside>
    <div class="tight">
      <div class="logo">
        <a href="/">
          <span class="logo-bear"
            ><img alt="Edited" src="/login/assets/images/edited-logo.svg"
          /></span>
        </a>
      </div>

      <router-outlet></router-outlet>

      <footer>
        <div class="copyright">
          <span>&copy; {{ year }}</span
          >&nbsp;<a
            href="https://edited.net/"
            rel="noopener noreferrer"
            target="_blank"
            >EDITED</a
          >
        </div>
        <ul>
          <li>
            <a
              href="//linkedin.com/company/edited"
              rel="noopener noreferrer"
              target="_blank"
              title="LinkedIn"
            >
              <img
                alt="LinkedIn"
                src="/login/assets/images/social/linkedin.svg"
              />
            </a>
          </li>
          <li>
            <a
              href="//instagram.com/edited_hq"
              rel="noopener noreferrer"
              target="_blank"
              title="Instagram"
            >
              <img
                alt="Instagram"
                src="/login/assets/images/social/instagram.svg"
              />
            </a>
          </li>
          <li>
            <a
              href="//www.tiktok.com/@editedhq"
              rel="noopener noreferrer"
              target="_blank"
              title="TikTok"
            >
              <img alt="TokTok" src="/login/assets/images/social/tiktok.svg" />
            </a>
          </li>
          <li>
            <a
              href="//facebook.com/geteditd"
              rel="noopener noreferrer"
              target="_blank"
              title="Facebook"
            >
              <img
                alt="Facebook"
                src="/login/assets/images/social/facebook.svg"
              />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </aside>

  <section>
    <edtd-marketing-content></edtd-marketing-content>
  </section>
</main>
