import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RoutesConfigService } from '../routes-config/routes-config.service';

export interface IAppConfigService {
  load(): Observable<any>;
  getConfig(key?: string): any;
  setConfig(configData: any): void;
}

export type TAppConfig = { [key: string]: any } | null;

@Injectable({
  providedIn: 'root',
})
export class AppConfigService implements IAppConfigService {
  private config: TAppConfig;

  constructor(
    private http: HttpClient,
    private routesCfg: RoutesConfigService
  ) {}

  public load(): Observable<TAppConfig> {
    return this.http.get(this.routesCfg.config());
  }

  public getConfig(key?: string): TAppConfig {
    if (key && this.config) {
      return this.config[key];
    }

    return this.config;
  }

  public setConfig(configData: TAppConfig): void {
    this.config = configData;
  }
}
