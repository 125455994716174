<div class="pre-loader-overlay">
  <div class="pre-loader-centering">
    <div class="pre-loader-content">
      <div class="loader-indicator">
        <img
          class="circle"
          src="/login/assets/images/svg/loading-spinner.svg"
        />
      </div>
    </div>
  </div>
</div>
