import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export class PasswordValidator {
  static isValid(minChars = 8, minCharGroups = 2): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.value;
      const x = password.match(new RegExp('.{' + minChars + ',}')); // At least minChars chars
      const a = password.match(/[0-9]+/) ? 1 : 0; // Digits
      const b = password.match(/[A-Z]+/) ? 1 : 0; // Upper case letters
      const c = password.match(/[a-z]+/) ? 1 : 0; // Lower case letters

      if (a + b + c >= minCharGroups && x !== null) {
        return null;
      }

      return { passwordValid: false };
    };
  }
}

export class ConfirmPasswordValidator {
  static isValid(): ValidatorFn {
    return (fg: FormGroup): { [key: string]: boolean } | null => {
      const password = fg.get('password').value;
      const confirmPassword = fg.get('confirmPassword').value;

      if (password !== confirmPassword) {
        return { passwordmismatch: true };
      }

      return null;
    };
  }
}
