<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="forget-form"
  name="resetPasswordForm"
  novalidate
>
  <h1 class="form-title">New Password Request</h1>

  <div
    *ngIf="
      errorResetingPassword ||
      isFieldInvalid('email') ||
      isFieldInvalid('password') ||
      isFieldInvalid('confirmPassword') ||
      (form.controls.password.valid && form.hasError('passwordmismatch'))
    "
    class="error-container error"
  >
    <img src="/login/assets/images/error.svg" />
    <div class="error-message">
      <p *ngIf="errorResetingPassword">{{ errorResetingPassword }}</p>
      <p *ngIf="isFieldInvalid('email')">Invalid Email Format</p>
      <p
        *ngIf="isFieldInvalid('password') || isFieldInvalid('confirmPassword')"
      >
        Invalid Password
      </p>
      <p *ngIf="form.hasError('passwordmismatch')">Password does not match.</p>
    </div>
  </div>

  <div [class.error]="isFieldInvalid('email')" class="form-group">
    <div class="input-icon left">
      <div class="form-input">
        <label>Email</label>
        <input edtdAutofocus formControlName="email" type="email" />
      </div>
    </div>
  </div>

  <div [class.error]="isFieldInvalid('password')" class="form-group">
    <div class="input-icon left">
      <div class="form-input">
        <label>New Password</label>
        <input edtdShowPassword formControlName="password" type="password" />
      </div>
    </div>
  </div>

  <div [class.error]="isFieldInvalid('confirmPassword')" class="form-group">
    <div class="input-icon left">
      <div class="form-input">
        <label>Confirm Password</label>
        <input
          edtdShowPassword
          formControlName="confirmPassword"
          type="password"
        />
      </div>
    </div>
  </div>

  <div class="form-actions">
    <button class="btn primary full-width" id="forget-btn" type="submit">
      Reset Password
    </button>
    <a class="btn secondary full-width" href="/">Back to Log in</a>
  </div>
</form>
