import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edtd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public year: number;

  public ngOnInit(): void {
    this.year = new Date().getFullYear();
  }
}
