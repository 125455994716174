import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConfigResolver } from './resolvers/app-config-resolver';
import { LocationResolver } from './resolvers/location-resolver';
import { ResetPasswordPageComponent } from './pages/reset-password/reset-password-page.component';
import { RequestPasswordPageComponent } from './pages/request-password/request-password-page.component';
import { SelectorPageComponent } from './pages/selector/selector-page.component';
import { LoginPageComponent } from './pages/login/login-page.component';

const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent,
    resolve: {
      location: LocationResolver,
      appConfig: AppConfigResolver,
    },
  },
  {
    path: 'resetpassword',
    component: ResetPasswordPageComponent,
    resolve: {
      appConfig: AppConfigResolver,
    },
  },
  {
    path: 'requestpassword',
    component: RequestPasswordPageComponent,
    resolve: {
      appConfig: AppConfigResolver,
    },
  },
  {
    path: 'selector',
    component: SelectorPageComponent,
    children: [
      {
        path: 'support',
        component: SelectorPageComponent,
      },
    ],
  },
  {
    path: '**',
    component: LoginPageComponent,
    resolve: {
      location: LocationResolver,
      appConfig: AppConfigResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
