import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AppConfigService,
  TAppConfig,
} from '../services/app-config/app-config.service';
import { Location } from '@angular/common';

@Injectable()
export class AppConfigResolver {
  constructor(
    private appCfg: AppConfigService,
    private location: Location
  ) {}

  private redirect(path: string) {
    this.location.go(path);
    location.reload();
  }

  resolve(): Observable<TAppConfig> {
    return new Observable((observer) => {
      const hasConfig = Boolean(this.appCfg.getConfig());

      if (hasConfig) {
        observer.next(this.appCfg.getConfig());
        observer.complete();
      } else {
        this.appCfg.load().subscribe({
          next: (config) => {
            this.appCfg.setConfig(config);

            observer.next(config);
            observer.complete();
          },
          error: (err) => {
            if (
              err?.error?.message ===
              'channel must be provided when loading config with valid session'
            ) {
              const url = new URL(location.href);
              const appUrl = url.searchParams.get('appUrl');

              if (appUrl?.includes('account=')) {
                location.href = decodeURIComponent(appUrl);
              } else if (appUrl) {
                this.redirect(`/selector?appUrl=${encodeURIComponent(appUrl)}`);
              } else {
                this.redirect('/selector');
              }
            }
          },
        });
      }
    });
  }
}
