import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare interface IWindow {
  location: Location;
}

declare const window: IWindow;

interface IResolverResult {
  search: string;
  hash: string;
  absUrl: string;
}

@Injectable()
export class LocationResolver {
  resolve(): Observable<IResolverResult> {
    return new Observable((observer) => {
      let locationHash = window.location.hash;

      if (locationHash.startsWith('#/%23%2F')) {
        locationHash = locationHash.substring(2);
      }

      locationHash = decodeURIComponent(locationHash);

      if (locationHash.startsWith('##/')) {
        locationHash = locationHash.substring(1);
      }

      observer.next({
        search: decodeURIComponent(window.location.search),
        hash: locationHash,
        absUrl: window.location.href,
      });
      observer.complete();
    });
  }
}
