import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subscription, map } from 'rxjs';

import { PasswordValidator } from '../../validators/password';
import { AppConfigService } from '../../services/app-config/app-config.service';
import { CookieService } from '../../services/cookie/cookie.service';
import { LoginService } from '../../services/login/login.service';

import { ILoginState } from '../../models/login';
import {
  IRecaptchaAction,
  RecaptchaActionKey,
} from '../../models/recaptcha-action';
import { EmailValidator } from '../../validators/email';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'edtd-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit, OnDestroy {
  get loginState(): ILoginState {
    return this.currentLoginState;
  }

  @Input()
  set loginState(state: ILoginState) {
    this.currentLoginState = state;
  }

  @Output()
  updateState: EventEmitter<ILoginState> = new EventEmitter<ILoginState>();

  public form: FormGroup;

  protected currentLoginState: ILoginState;

  private subscriptions: Subscription[] = [];
  private recaptchaToken: string;

  constructor(
    private loginSvc: LoginService,
    private formBuilder: FormBuilder,
    private appCfg: AppConfigService,
    private cookieSvc: CookieService,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(
      this.loginSvc.recaptchaControls.subscribe((recaptcha: IRecaptchaAction) =>
        this.onRecaptcha(recaptcha)
      )
    );
  }

  public ngOnInit() {
    const passwordStrength = this.appCfg.getConfig('password');

    this.form = this.formBuilder.group({
      username: [
        this.loginState.username,
        Validators.compose([Validators.required, EmailValidator.isValid()]),
      ],
      password: [
        '',
        {
          validators: Validators.compose([
            Validators.required,
            PasswordValidator.isValid(
              passwordStrength.minChars,
              passwordStrength.minCharGroups
            ),
          ]),
          updateOn: 'submit',
        },
      ],
    });
  }

  public get searchParamsWithoutEmail$(): Observable<Record<string, string>> {
    return this.route.queryParams.pipe(
      map((params) => {
        const newParams = { ...params };

        delete newParams.email;

        return newParams;
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public isFieldInvalid(fieldName: string): boolean {
    return this.loginSvc.isFieldInvalid(
      this.form,
      fieldName,
      fieldName !== 'username'
    );
  }

  public goToLogin(): void {
    this.updateState.emit({ component: 'login' });

    this.cookieSvc.setCookie('saved_username', '');
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const loginParams: any = {};

      loginParams.action = 'doLogin';
      loginParams.password = this.form.get('password').value;

      if (this.recaptchaToken) {
        loginParams.recaptchaResponse = this.recaptchaToken;
      } else if (this.loginState.isCaptchaRequired) {
        this.loginSvc.recaptchaControls.next({
          action: RecaptchaActionKey.Invalidate,
        });

        return;
      }

      this.updateState.emit(Object.assign({}, this.loginState, loginParams));
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  private onRecaptcha(recaptcha: IRecaptchaAction): void {
    if (recaptcha.action === RecaptchaActionKey.SetToken) {
      this.recaptchaToken = recaptcha.value;
    }
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    this.loginSvc.validateAllFormFields(formGroup);
  }
}
