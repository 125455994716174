import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

const hostname = window.location.hostname;

if (hostname !== 'localhost') {
  Sentry.init({
    environment: hostname.endsWith('.dynamicaction.com')
      ? 'development'
      : hostname.endsWith('.stg.edited.com')
        ? 'staging'
        : hostname.endsWith('.edited.com')
          ? 'production'
          : 'unknown',
    dsn: 'https://6cf24867e7ebc74b8e17ac49cc233d5b@o2776.ingest.us.sentry.io/4506971731525632',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.log(err));
