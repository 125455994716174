import { Component, OnInit } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { ChannelKey, IAccount } from '../../models/account-channel';
import { SelectorService } from '../../services/selector/selector.service';

@Component({
  selector: 'edtd-selector-page',
  templateUrl: './selector-page.component.html',
  styleUrls: ['./selector-page.component.scss'],
})
export class SelectorPageComponent implements OnInit {
  public accountsChannels: IAccount[];
  public dropdownOpen = false;
  public rememberSelection = false;
  public displayAccounts: IAccount[];
  public currentAccount: IAccount;
  public isInit = false;
  public currentAccountId: string;

  private allAccounts: { [key: string]: any };

  constructor(private selectorSvc: SelectorService) {}

  ngOnInit() {
    const observables: Observable<any[]> = zip(
      this.selectorSvc.getAllAccounts(),
      this.selectorSvc.getAccessConfig()
    );

    observables.subscribe(
      ([allAccounts, accessConfig]) => {
        const accountsChannels =
          this.selectorSvc.getAccountsChannels(accessConfig);

        this.allAccounts = allAccounts;
        this.accountsChannels = accountsChannels.data;

        const selection = this.selectorSvc.getRememberSelection();

        if (selection) {
          const canRedirectToCachedSelection =
            this.selectorSvc.isCachedSelectionAllowed(
              this.accountsChannels,
              selection
            );

          if (canRedirectToCachedSelection) {
            this.navigateToApp(
              selection.account,
              selection.channel as ChannelKey
            );
            return;
          }

          this.selectorSvc.setRememberSelection();
        }

        if (
          this.accountsChannels.length === 1 &&
          this.accountsChannels[0].channels.length === 1
        ) {
          this.selectorSvc.initRedirect(
            this.accountsChannels[0].accountId,
            this.accountsChannels[0].channels[0].id
          );
        } else {
          this.currentAccountId =
            accountsChannels.lastAccount || accountsChannels[0].accountId;

          this.setDisplayStructures();

          this.isInit = true;
        }
      },
      () => {
        this.selectorSvc.logoutRedirect();
      }
    );
  }

  /**
   * Toggle expand/collapse for the account dropdown
   */
  public toggleDropdown(shouldClose?: boolean): void {
    if (shouldClose) {
      this.dropdownOpen = false;
    } else {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }

  /**
   * Set current account and update dropdown structure
   */
  public setCurrentAccount(account: IAccount): void {
    this.currentAccountId = account.accountId;

    this.currentAccount = this.accountsChannels.find(
      (item) => item.accountId === this.currentAccountId
    );

    this.toggleDropdown(true);
  }

  /**
   * Redirect to the app based on account and channel selection
   */
  public navigateToApp(
    accountId: string,
    channelId: ChannelKey = ChannelKey.Web
  ): void {
    if (this.rememberSelection) {
      this.selectorSvc.setRememberSelection(accountId, channelId);
    }

    this.selectorSvc.initRedirect(accountId, channelId);
  }

  private initAccountItem(accountItem: IAccount): IAccount {
    const account: { [key: string]: any } = this.allAccounts.rows.find(
      (acc) => acc.id === accountItem.accountId
    );
    const labels: string[] = [];

    if (account) {
      if (account.client && account.client.label) {
        labels.push(account.client.label);
      }
      if (!labels.includes(account.label)) {
        labels.push(account.label);
      }
    } else {
      labels.push(accountItem.accountId);
    }

    accountItem.label = labels.join(' ');

    accountItem.channels = this.selectorSvc.sortChannels(accountItem.channels);

    return accountItem;
  }

  /**
   * Set display structures, filtering out selected account from the account dropdown
   */
  private setDisplayStructures(): void {
    this.displayAccounts = this.accountsChannels
      .reduce((accounts, item) => {
        item = this.initAccountItem(item);

        if (this.currentAccountId && this.currentAccountId === item.accountId) {
          this.currentAccount = item;
        }

        accounts.push(item);

        return accounts;
      }, [])
      .sort((a: IAccount, b: IAccount): any => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();

        return labelA.localeCompare(labelB);
      });
  }
}
