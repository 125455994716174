import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CookieService } from '../../services/cookie/cookie.service';
import { LoginService } from '../../services/login/login.service';

import { EmailValidator } from '../../validators/email';
import { ILoginState } from '../../models/login';

@Component({
  selector: 'edtd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  get loginState(): ILoginState {
    return this.currentLoginState;
  }

  @Input()
  set loginState(state: ILoginState) {
    this.currentLoginState = state;
  }

  @Output()
  updateState: EventEmitter<ILoginState> = new EventEmitter<ILoginState>();

  public form: FormGroup;
  protected currentLoginState: ILoginState;

  constructor(
    private formBuilder: FormBuilder,
    private cookieSvc: CookieService,
    private loginSvc: LoginService
  ) {}

  public ngOnInit() {
    const emailAddress: string =
      this.cookieSvc.getCookie('saved_username') || null;
    const emailParam = this.loginSvc.getEmailParam();

    this.form = this.formBuilder.group({
      email: [
        emailAddress,
        {
          validators: Validators.compose([
            Validators.required,
            EmailValidator.isValid(),
          ]),
          updateOn: 'submit',
        },
      ],
      remember: [Boolean(emailAddress)],
    });

    if (emailParam) {
      this.form.get('email').setValue(emailParam);
      this.form.get('remember').setValue(false);

      this.onSubmit();
    }
  }

  public isFieldInvalid(fieldName: string): boolean {
    return this.loginSvc.isFieldInvalid(this.form, fieldName);
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.loginSvc.email = this.form.get('email').value;

      this.updateState.emit(
        Object.assign({}, this.loginState, {
          action: 'doStrategy',
          username: this.loginSvc.email,
          isRemember: this.form.get('remember').value,
        })
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    this.loginSvc.validateAllFormFields(formGroup);
  }
}
