<edtd-loading *ngIf="isLoading"></edtd-loading>
<div [ngSwitch]="loginState.component">
  <edtd-login
    [hidden]="isLoading"
    (updateState)="onStateChange($event)"
    [loginState]="loginState"
    *ngSwitchCase="'login'"
  ></edtd-login>
  <edtd-password
    [hidden]="isLoading"
    (updateState)="onStateChange($event)"
    [loginState]="loginState"
    *ngSwitchCase="'password'"
  ></edtd-password>
  <edtd-sso-login-error
    [hidden]="loginState.component !== 'sso-error' || isLoading"
    (updateState)="onStateChange($event)"
    [loginState]="loginState"
    *ngSwitchCase="'sso-error'"
  ></edtd-sso-login-error>
</div>
