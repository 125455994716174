<form
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="forget-form"
  name="requestPasswordForm"
  novalidate
>
  <h1 class="form-title">New Password Request</h1>

  <p class="big">
    Please use this form if you are a registered user and you have forgotten
    your password, or you are a new registered user requesting your password for
    the first time.
  </p>

  <div
    *ngIf="isFieldInvalid('email') || isRequestError || isResetError"
    class="error-container error"
  >
    <img src="/login/assets/images/error.svg" />
    <div class="error-message">
      <p *ngIf="isFieldInvalid('email')">Invalid Email Format</p>
      <p *ngIf="isRequestError">
        Internal Error. We are unable to process your request at this time,
        please try again.
      </p>
      <p *ngIf="isResetError">
        <strong>Error: Link Expired</strong><br />
        Please submit a new request to change your password
      </p>
    </div>
  </div>

  <div [class.error]="isFieldInvalid('email')" class="form-group">
    <div class="input-icon left">
      <label>Email</label>
      <div class="form-input">
        <input
          edtdAutofocus
          formControlName="email"
          spellcheck="false"
          type="email"
        />
      </div>
    </div>
  </div>

  <div class="form-group">
    <edtd-recaptcha></edtd-recaptcha>
  </div>

  <div class="form-actions">
    <button class="btn primary full-width" type="submit">Reset Password</button>

    <a class="btn secondary full-width" href="/" id="back-btn"
      >Back to Log in</a
    >
  </div>
</form>
