import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[edtdShowPassword]',
})
export class ShowPasswordDirective implements AfterViewInit {
  private shown = false;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const parent = this.el.nativeElement.parentNode;
    const label = document.createElement('label');
    const icon = document.createElement('i');

    label.innerHTML = 'Show';
    label.classList.add('show-label');
    icon.classList.add('fa', 'fa-eye');
    label.addEventListener('click', () => {
      this.toggle(label, icon);
    });
    parent.prepend(icon);
    parent.prepend(label);
  }

  public toggle(label: HTMLElement, icon: HTMLElement) {
    this.shown = !this.shown;

    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      label.innerHTML = 'Hide';
      icon.classList.remove('fa-eye');
      icon.classList.add('fa-eye-slash');
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      label.innerHTML = 'Show';
      icon.classList.add('fa-eye');
      icon.classList.remove('fa-eye-slash');
    }
  }
}
