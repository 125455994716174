export enum ChannelKey {
  Web = 'Web',
  Store = 'Store',
  Omni = 'Omni',
}

export interface IChannel {
  id: ChannelKey;
  label: string;
  noChannels?: boolean;
}

export interface IAccount {
  status: string;
  accountId: string;
  label?: string;
  channels: IChannel[];
}
