<div class="login-account-channel" *ngIf="isInit">
  <h1 *ngIf="accountsChannels.length > 1">Select Account</h1>
  <h3 *ngIf="accountsChannels.length === 1">
    {{ currentAccount?.label }}
  </h3>

  <div
    class="account-selection form-group"
    edtdClickOutside
    (clickOutside)="toggleDropdown($event)"
    *ngIf="accountsChannels.length > 1"
  >
    <label class="labels">Account</label>
    <button
      class="selector-toggle btn"
      (click)="toggleDropdown()"
      title="{{ currentAccount?.label }}"
    >
      <span class="group-label pull-left">{{
        currentAccount?.label | uppercase
      }}</span>
      <span class="pull-right">
        <svg width="16" height="10">
          <polygon points="0,0 16,0 8,10" />
        </svg>
      </span>
    </button>
    <ul class="selector-menu" *ngIf="dropdownOpen">
      <li *ngFor="let account of displayAccounts" title="{{ account.label }}">
        <a
          class="channel-select"
          (click)="setCurrentAccount(account)"
          *ngIf="account.accountId !== currentAccountId"
        >
          {{ account.label }}
        </a>
      </li>
    </ul>
  </div>

  <div class="remember-choice row">
    <input
      id="rememberSelection"
      class="pull-left"
      type="checkbox"
      [(ngModel)]="rememberSelection"
    />
    <label for="rememberSelection" class="labels pull-left"
      >Remember my selection</label
    >
  </div>

  <div class="form-actions">
    <div class="row">
      <div class="col-md-12">
        <button
          (click)="navigateToApp(currentAccountId)"
          type="button"
          class="btn primary full-width"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
