import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { RoutesConfigService } from '../routes-config/routes-config.service';

import { IRecaptchaAction } from '../../models/recaptcha-action';
import {
  ILoginRequest,
  ILoginSuccessResponse,
  IStrategySuccessResponse,
} from '../../models/login';

declare interface IWindow {
  location: Location;
}

declare const window: IWindow;

export interface ILoginService {
  hasLogout(url: string): boolean;

  hasRequestNewPass(url: string): boolean;

  hasResetPass(url: string): boolean;

  hasLoginErrCode(url: string): boolean;

  login(loginParams: any): Observable<any>;

  strategy(user: string, queryParams?: any): Observable<any>;

  initRedirection(
    redirectPage: string,
    accountId?: string,
    channelId?: string
  ): void;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService implements ILoginService {
  private recaptchaSubject: Subject<IRecaptchaAction> =
    new Subject<IRecaptchaAction>();

  private selectedEmail = '';

  constructor(
    private http: HttpClient,
    private routesCfg: RoutesConfigService
  ) {}

  public set email(value: string) {
    this.selectedEmail = value;
  }

  public get email(): string {
    return this.selectedEmail;
  }

  protected get location(): Location {
    return window.location;
  }

  public getEmailParam(): string {
    const url = new URL(this.location.href);
    const email = url.searchParams.get('email');

    if (email) {
      return decodeURIComponent(email);
    }

    return null;
  }

  public hasLogout(url: string): boolean {
    return url.includes('logout=1');
  }

  public hasGID(url: string, decode = true): boolean {
    if (decode) {
      url = decodeURIComponent(url);
    }
    return url.includes('/gid/utm_source=');
  }

  public hasRegularLink(url: string): boolean {
    const regEx = new RegExp('^(.*)/Web/|/Omni/|/Store/(.*)$');

    return regEx.test(decodeURIComponent(url));
  }

  public hasRequestNewPass(url: string): boolean {
    return url.includes('requestnewpass=');
  }

  public hasResetPass(url: string): boolean {
    return url.includes('resetpassword=');
  }

  public hasLoginErrCode(url: string): boolean {
    return url.includes('loginErrCode=');
  }

  public hasSetUsername(url: string): boolean {
    return url.includes('setusername=');
  }

  public hasRedirectLink(url: string): boolean {
    return url.includes('appUrl=');
  }

  public login(loginParams: ILoginRequest): Observable<ILoginSuccessResponse> {
    return this.http.post<ILoginSuccessResponse>(
      this.routesCfg.login(),
      loginParams
    );
  }

  public strategy(
    user: string,
    queryParams?: any
  ): Observable<IStrategySuccessResponse> {
    return this.http.get<IStrategySuccessResponse>(
      this.routesCfg.strategy(user, queryParams)
    );
  }

  public initRedirection(
    redirectPage = '',
    accountId?: string,
    channelId?: string
  ): void {
    if (redirectPage.length > 1) {
      this.location.hash = redirectPage;
    } else {
      let hash = [redirectPage, redirectPage.slice(-1) === '/' ? '' : '/'].join(
        ''
      );

      if (accountId && channelId) {
        hash += `${accountId}/${channelId}/`;
      }

      this.location.hash = hash;
    }

    this.location.reload();
  }

  public get recaptchaControls(): Subject<IRecaptchaAction> {
    return this.recaptchaSubject;
  }

  public isFieldInvalid(
    form: FormGroup,
    fieldName: string,
    dirtyCheck = true
  ): boolean {
    const field: AbstractControl = form.get(fieldName);

    if (!dirtyCheck) {
      return !field.valid;
    }

    return !field.valid && field.dirty;
  }

  public validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
