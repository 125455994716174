import { Injectable } from '@angular/core';

export interface IRoutesConfigService {
  login(): string;

  strategy(user: string, queryParams?: any): string;

  requestPassword(): string;

  resetPassword(): string;

  config(): string;

  localization(locale: string): string;

  accounts(): string;

  userAccess(): string;
}

@Injectable({
  providedIn: 'root',
})
export class RoutesConfigService implements IRoutesConfigService {
  private routes: { [key: string]: string };

  constructor() {
    this.routes = {
      login: '/login',
      strategy: '/login/strategy',
      requestPassword: '/login/reset_password',
      resetPassword: '/login/reset_password?x',
      localization: '/l10n',
      config: '/config',
      accounts: '/accounts',
      access: '/access',
    };
  }

  public login(): string {
    return this.getRoute('login');
  }

  public strategy(user: string, queryParams?: any): string {
    const query = this.generateQuery(queryParams);

    return `${this.getRoute('strategy')}/${user}${query}`;
  }

  public requestPassword(): string {
    return this.getRoute('requestPassword');
  }

  public resetPassword(): string {
    return this.getRoute('resetPassword');
  }

  public config(): string {
    return this.getRoute('config');
  }

  public localization(locale: string): string {
    return this.getRoute('localization') + `/${locale}`;
  }

  public accounts(): string {
    return this.getRoute('accounts') + '?denormalize=client';
  }

  public userAccess(): string {
    return this.getRoute('access');
  }

  private generateQuery(queryParams, query?) {
    query = query || '';

    if (typeof queryParams === 'object' && !Array.isArray(queryParams)) {
      const params = [];

      for (const key in queryParams) {
        if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
          params.push(`${key}=${queryParams[key]}`);
        }
      }

      if (query.length) {
        query += '&' + params.join('&');
      }

      if (params.length && !query.length) {
        query += '?' + params.join('&');
      }
    }

    if (Array.isArray(queryParams)) {
      for (const key of queryParams) {
        query = this.generateQuery(key, query);
      }
    }

    return query;
  }

  private getRoute(routeId: string): string {
    const prefix = '/api';
    return prefix + this.routes[routeId];
  }
}
